import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { GMapModule } from "primeng/gmap";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { CarouselModule } from "primeng/carousel";
import { DialogModule } from "primeng/dialog";

import { AppComponent } from "./app.component";
import { StoreLocatorComponent } from "../app/store-locator/store-locator.component";
import { AppRoutingModule } from "./app-routing.module";
import { LocListComponent } from "./loc-list/loc-list.component";
import { SingleLocComponent } from "./single-loc/single-loc.component";

import { DatashareService } from "./datashare.service";

@NgModule({
  declarations: [
    AppComponent,
    StoreLocatorComponent,
    LocListComponent,
    SingleLocComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    GMapModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
    CarouselModule,
    DialogModule,
    AppRoutingModule,
  ],
  providers: [DatashareService],
  bootstrap: [AppComponent],
})
export class AppModule {}
