import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DatashareService } from '../datashare.service';
import { Router, UrlSegment } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-single-loc',
  templateUrl: './single-loc.component.html',
  styleUrls: ['./single-loc.component.scss'],
})
export class SingleLocComponent implements OnInit, AfterViewInit
{
  baseURL: string;
  isCollapsed: boolean = true; //location is contained in side panel
  singleLocTemplate: any; //getting through service from store-locator
  storeCode: string;
  selectedLoc: any;
  routeSegments: UrlSegment[];

  constructor(
    private sharedData: DatashareService,
    private http: HttpClient,
    public route: ActivatedRoute,
    public router: Router,
  ) {
    this.baseURL = this.sharedData.getBaseURL();
    this.route.pathFromRoot[1].url.subscribe(values => this.routeSegments = values);
  }

  ngOnInit(): void {
    this.singleLocTemplate = this.sharedData.getSingleLocTemplate();
    this.getLocationByStoreCode();
  }

  ngAfterViewInit(): void {
    document.querySelectorAll('.desktop-view .scrollable-bar')[0].scrollTop = 0;
  }

  getLocationByStoreCode() {
    console.log('this.routeSegments: ', this.routeSegments);
    let state = this.routeSegments[0].path.toLocaleLowerCase();
    let city  = this.routeSegments[1].path.toLocaleLowerCase().replace(/\W/g, '-');
    let addr  = this.routeSegments[2].path.toLocaleLowerCase().replace(/\W/g, '-');
    let slug = `${state}/${city}/${addr}`;

    let URL = `${this.baseURL}&where=slug='${slug}'`;
    console.log("direct URL: ", URL);

    return this.http.get<any>(URL).toPromise()
      .then((resp: any) => {
        if (resp.data) {
          //call returned data
          let locList = resp.data;
          this.selectedLoc = locList[0];
          if (locList.length > 1) {
            this.selectedLoc._subLocs = [];
            for (let i = 1; i < locList.length; ++i) {
              this.selectedLoc._subLocs.push(locList[i]);
            }
          }
          this.sharedData.setSelectedLoc(this.selectedLoc);
          console.log("this.selectedLoc: ", this.selectedLoc);
        }

        if (resp.status == "err") {
          //the call returned error message
          console.log("Error getting location:", resp.message);
        }
      }, err => {
        //promise returned error
        console.log("Error resolving promise:", err.message);
      });
  }

}
