import { Injectable } from "@angular/core";
import * as Rx from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class DatashareService {
  baseURL: string;

  //pass templates from store-locator to router children
  cardTemplate: any;
  noResultsTemplate: any;
  loadingTemplate: any;
  singleLocTemplate: any;

  //coming from store locator on marker click
  highlightedStoreCode: string;

  locList: any[] = []; //needed by loc-list + store locator
  selectedLoc: any; //needed by single-loc + store-locator
  locListWithActiveLocs: any[] = []; //needed by loc-list + store locator

  subjectByIdent: any = {};

  constructor(private http: HttpClient) {}

  //separate subscription subjects
  getSubject(ident: string): Rx.Subject<any> {
    let subj = this.subjectByIdent[ident];
    if (!subj) {
      subj = this.subjectByIdent[ident] = new Rx.Subject<any>();
    }
    return subj;
  }

  subscribe(ident: string, ...callbacks) {
    let subj = this.getSubject(ident);
    return subj.subscribe(...callbacks);
  }

  notify(ident: string, args?: any) {
    console.log("Notification '%s': %j", ident, args);
    let subj = this.getSubject(ident);
    subj.next(args);
  }

  //called on first load
  loadLocList(URL) {
    this.locList = [];
    let topLocBy_id = {};

    this.notify("location-list-loading");
    return this.http
      .get<any>(URL)
      .toPromise()
      .then(
        (resp: any) => {
          if (resp.data) {
            this.locListWithActiveLocs = resp.data.filter(
              (locs) => locs._status !== "R"
            );
            for (let l of this.locListWithActiveLocs) {
              if (l._parent_id) {
                // this is a sub-location
                // add it under the parent
                if (topLocBy_id[l._parent_id])
                  topLocBy_id[l._parent_id].push(l);
              } else {
                // this is a top location
                // add it to the main locList and create a sub-loc array
                this.locList.push(l);
                topLocBy_id[l._id] = l._subLocs = [];
              }
            }

            this.notify("location-list-loaded", {
              list: this.locList,
              isEmpty: this.locList.length == 0,
            });
            return this.locList;
          } else {
            console.log("Error loading locations:", resp.message);
            this.notify("location-list-error", { err: resp.message });
          }
        },
        (err) => {
          console.log("Error loading locations:", err.message);
        }
      );
  }

  setLocList(locList) {
    this.locList = locList;
    setTimeout(() => {
      this.notify("location-list-loaded", {
        list: this.locList,
        isEmpty: this.locList.length == 0,
      });
    });
  }

  getLocList() {
    return this.locList;
  }

  setBaseURL(url) {
    this.baseURL = url;
    return this;
  }

  getBaseURL() {
    return this.baseURL;
  }

  //used by loc-list for cards + store-locator for POIs
  setHighlightedStoreCode(highlightedStoreCode) {
    this.highlightedStoreCode = highlightedStoreCode;
    this.notify("highlight-changed", {
      highlightedStoreCode: this.highlightedStoreCode,
    });
  }

  getHighlightedStoreCode() {
    return this.highlightedStoreCode;
  }

  //used by single-loc to signal single location was loaded
  setSelectedLoc(selectedLoc) {
    this.selectedLoc = selectedLoc;
    this.notify("single-location-loaded", { selectedLoc: this.selectedLoc });
  }

  //needed by store-locator to get single location to update map
  getSelectedLoc() {
    return this.selectedLoc;
  }

  //card templates for router outlet children
  setCardTemplate(cardTemplate) {
    this.cardTemplate = cardTemplate;
  }

  getCardTemplate() {
    return this.cardTemplate;
  }

  setNoResultsTemplate(noResultsTemplate) {
    this.noResultsTemplate = noResultsTemplate;
  }

  getNoResultsTemplate() {
    return this.noResultsTemplate;
  }

  setLoadingTemplate(loadingTemplate) {
    this.loadingTemplate = loadingTemplate;
  }

  getLoadingTemplate() {
    return this.loadingTemplate;
  }

  setSingleLocTemplate(singleLocTemplate) {
    this.singleLocTemplate = singleLocTemplate;
  }

  getSingleLocTemplate() {
    return this.singleLocTemplate;
  }
}
