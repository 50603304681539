import { Component, OnInit, OnDestroy, AfterContentInit, ElementRef, ViewChildren, QueryList, NgZone } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DatashareService } from "../datashare.service";

@Component({
  selector: 'app-loc-list',
  templateUrl: './loc-list.component.html',
  styleUrls: ['./loc-list.component.scss']
})
export class LocListComponent implements OnInit, OnDestroy, AfterContentInit
{
  @ViewChildren("cardsContainer") cardList: QueryList<ElementRef>;

  locList: any[] = []; //extracting through service
  results: boolean = false;
  loading: boolean = false;

  highlightedStoreCode: string;

  sortOptions: any[] = [
    { name: "City Name", code: "city" },
    { name: "State",     code: "state" },
    { name: "Near you",  code: "near" },
  ];
  selSort:           any;
  dirUrl:            string;
  selectedLoc:       any;
  cardTemplate:      any;
  noResultsTemplate: any;
  loadingTemplate:   any;

  locationListLoaded;
  locationListError;
  highlightChanged;

  constructor(
    private sharedData: DatashareService,
  ) { }

  ngOnDestroy(): void {
    this.locationListLoaded.unsubscribe();
    this.locationListError.unsubscribe();
    this.highlightChanged.unsubscribe();
  }


  ngOnInit(): void {
    // this.loading = true;
//    this.locList = this.sharedData.getLocList();

    //get template cards from parent through shared service
    this.cardTemplate = this.sharedData.getCardTemplate();
    this.noResultsTemplate = this.sharedData.getNoResultsTemplate();
    this.loadingTemplate = this.sharedData.getLoadingTemplate();

    //set default sort option
    this.selSort = { name: "Near you", code: "near" };

    this.locationListLoaded = this.sharedData.subscribe('location-list-loaded', (repl: any) => {
      console.log('loc-list loaded repl:', repl);
      this.loading = false;

      if (repl.list.length > 0) {
        this.results = true;
        this.locList = repl.list;
        console.log('this.locList :', this.locList);
      } else {
        this.results = false;
      }
    });

    this.locationListError = this.sharedData.subscribe('location-list-error', (repl: any) => {
      console.log("Error loading locations:", repl.err);
      this.results = false;
    });

    this.highlightChanged = this.sharedData.subscribe('highlight-changed', (repl: any) => {
      console.log('loc-list highlight repl:', repl);

      if (repl.highlightedStoreCode) {
        this.highlightedStoreCode = repl.highlightedStoreCode;

        setTimeout(() => {
          const el = this.cardList.find((r) => r.nativeElement.classList.value == "ng-star-inserted loc-card highlight");
          el?.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        }, 0);
      }
    });

    console.log('this.highlightedStoreCode :', this.highlightedStoreCode);

  }

  ngAfterContentInit()
  {
  }

  buildCardList() {
    if (this.locList.length == 0) {
      this.results = false;
      this.loading = false;
    } else {
      this.results = true;
    }
  }

  highlightCard(storeCode) {
    this.highlightedStoreCode = storeCode;
    this.sharedData.setHighlightedStoreCode(this.highlightedStoreCode);
  }

  sortChanged(sortOption) {
    console.log('sortOption:', sortOption);
    if (sortOption == "city") {
      this.locList.sort((a, b) => a.city.localeCompare(b.city));
    }

    if (sortOption == "state") {
      this.locList.sort((a, b) => a.state.localeCompare(b.state));
    }

    if (sortOption == "near") {
      this.locList.sort((a, b) => a._geodistance - b._geodistance);
    }
  }
}
