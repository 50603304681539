import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { StoreLocatorComponent } from "./store-locator/store-locator.component";
import { LocListComponent } from "./loc-list/loc-list.component";
import { SingleLocComponent } from "./single-loc/single-loc.component";
import { AppComponent } from "./app.component";

const routes: Routes = [
  { path: "", component: LocListComponent },
  { path: "**", component: SingleLocComponent },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
