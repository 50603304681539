<div #locationsPanel class="locations-panel">
  <div class="card-list-container" *ngIf="!loading && results">
    <ng-template ngFor let-loc [ngForOf]="locList" let-i="index">
      <div
        #cardsContainer
        [ngClass]="{
          'loc-card': true,
          highlight: highlightedStoreCode == loc.storeCode
        }"
        (click)="highlightCard(loc.storeCode)"
      >
        <ng-container
          *ngTemplateOutlet="
            cardTemplate;
            context: {
              $implicit: loc,
              rowIndex: i
            }
          "
        ></ng-container>
      </div>
    </ng-template>
  </div>

  <div *ngIf="!loading && !results" style="margin-top: 20px">
    <ng-container *ngTemplateOutlet="noResultsTemplate"></ng-container>
  </div>

  <div *ngIf="loading">
    <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
  </div>
</div>
